
import { defineAsyncComponent, defineComponent, ref, watch } from "vue";
import { gpf } from "@/utils/global-functions";
import type { User } from "@/services/v1/admin/user/types";
import { popupStatus } from "@/utils/popup/status";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const LabelWithColon = defineAsyncComponent(
  () => import(`@/components/label/WithColon.vue`)
);

const servicesV1Admin = async () => await import(`@/services/v1/admin/user/index`);

export default defineComponent({
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ModalTemplate,
    LabelWithColon,
  },
  setup(props) {
    const modalId = "modal-detail-user";
    const onCloseClick = () => {
      gpf.hideModal(`#${modalId}`);
    };

    const detailUser = ref({} as User);
    const loading = ref(false as boolean);
    const getDetailUser = async () => {
      const { getUserById } = await servicesV1Admin();

      loading.value = true;
      try {
        const hit = await getUserById(props.userId);
        detailUser.value = hit.response;
      } catch (err: any) {
        console.log("err response getDetailUser ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => props.userId,
      () => {
        getDetailUser();
      }
    );

    return {
      modalId,
      detailUser,
      onCloseClick,
      loading,
    };
  },
});
